import React, { useState } from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
import { Menu, Close } from "styled-icons/material";
import logo from "../images/logo.png";

import logoLight from "../images/logo-light.png";
import {
  applyButton,
  flexColSbCenter,
  flexRowStartCenter,
  palette,
} from "../styles/styles";
import stain from "../images/sections/first.png";
import { Link } from "gatsby";

const DrawerMenu = (props) => {
  const { className } = props;
  const [showDrawerMenu, setShowDrawerMenu] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawerMenu(open);
  };

  const links = [
    { link: "/#content", text: "¿Qué incluye el Bootcamp?" },
    { link: "/#serviceCharge", text: "¿Cómo funciona?" },
    { link: "/#successStories", text: "Nuestros egresados" },
    { link: "/contact", text: "Contacto" },
  ];

  return (
    <>
      <div className={className}>
        <span role="button" onClick={toggleDrawer("left", true)}>
          {showDrawerMenu ? (
            <Close size={24} className="icon-option-drawer" />
          ) : (
            <Menu size={24} className="icon-option-drawer" />
          )}
        </span>
        <figure className="logo">
          <img src={logo} alt="lapieza.io" />
        </figure>
      </div>
      <Drawer
        className={className}
        anchor="left"
        open={showDrawerMenu}
        onClose={toggleDrawer(false)}
      >
        <div
          role="button"
          className="drawerMenu"
          id="drawerMenu"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div>
            <ul className="menuList">
              {links.map((l) => (
                <li key={l.link}>
                  <Link className="link" to={l.link}>
                    {l.text}
                  </Link>
                </li>
              ))}
            </ul>
            <a
              className="toForm"
              href="https://form.typeform.com/to/QhcRLoIg"
              target="_blank"
              rel="noopener no referrer"
            >
              Postúlate ahora
            </a>
          </div>
          <figure className="logoMenu">
            <img src={logoLight} alt="lapieza.io" />
          </figure>
        </div>
      </Drawer>
    </>
  );
};
export default styled(DrawerMenu)`
  width: 100%;
  height: 8rem;
  padding: 4%;
  ${flexRowStartCenter};
  @media (min-width: 769px) {
    display: none;
  }
  .drawerMenu {
    height: 100%;
    padding: 6rem 4rem 4rem 4rem !important;
    color: ${palette.whiteWhite};
    ${flexColSbCenter};
    background-color: ${palette.bgMenu};
    background-image: url(${stain});
    background-attachment: local;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 5rem -5rem;
    .menuList {
      font-size: 2rem;
      margin-bottom: 4rem;
      li {
        list-style: none;
        margin: 2.5rem 1rem;
        padding-bottom: 0.8rem;
        border-bottom: solid ${palette.whiteWhite} 1px;
        .link {
          margin: 2.5rem 1rem;
          padding-bottom: 0.8rem;
          text-decoration: none;
          color: ${palette.whiteWhite};
        }
      }
    }
    .toForm {
      ${applyButton};
      font-size: 2rem;
    }
    .logoMenu {
      width: 20rem;
    }
  }
  .logo {
    margin: 0 auto;
  }
`;
