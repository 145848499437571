import React from "react";
import styled from "styled-components";
import { Facebook, Linkedin, Instagram } from "styled-icons/boxicons-logos";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { palette } from "../styles/styles";
import logo from "../images/LogoMarble.png";
import footerImg from "../images/team.png";

const Footer = (props) => {
  const { className } = props;

  return (
    <footer className={className}>
      <div className="infoFooter">
        <div>
          <img
            style={{ cursor: "pointer" }}
            src={logo}
            alt="Lapieza.io Logo"
            onClick={() => window.open("https://lapieza.io/")}
          />
          <a href="tel:+52 55 6542 2798">+52 55 6542 2798</a>
          <h5>
            <a href="mailto:pol@lapieza.io">pol@lapieza.io</a>
          </h5>
        </div>
        <div>
          <h4>Empresa</h4>
          <hr />
          <ul>
            <li>
              <a
                href="https://lapieza.io/nosotros"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nosotros
              </a>
            </li>
            <li>
              <a
                href="https://lapieza.io/beneficios"
                target="_blank"
                rel="noopener noreferrer"
              >
                Beneficios
              </a>
            </li>
            <li>
              <a
                href="https://lapieza.io/faqs"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQs
              </a>
            </li>
            <li>
              <Link to="/contact">Contacto</Link>
            </li>
            <li>
              <a
                href="https://blog.lapieza.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h4>Candidato</h4>
          <hr />
          <ul>
            <li>
              <a
                href="https://lapieza.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Vacantes
              </a>
            </li>
            <li>
              <a
                href="https://lapieza.io/empresas"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buscar empresas
              </a>
            </li>
            <li>
              <a
                href="https://lapieza.io/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Iniciar sesion
              </a>
            </li>
            <li>
              <a
                href="https://lapieza.io/registro"
                target="_blank"
                rel="noopener noreferrer"
              >
                Regístrate
              </a>
            </li>
          </ul>
        </div>
        <div style={{ marginBottom: 32 }}>
          <h4 style={{ textAlign: "center" }}>
            También nos puedes encontrar en:
          </h4>
          <hr />
          <ul className="horizontal-list">
            <li>
              <a
                href="https://www.facebook.com/lapieza.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook size="32" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/28151948/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin size="32" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/lapieza.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram size="32" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <h5>© TALENTO STARTUP SAPI DE CV</h5>
      <img className="team-lapieza" src={footerImg} alt="Team | LaPieza.io" />
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  setTheme: PropTypes.func,
  selectedTheme: PropTypes.string,
};

Footer.defaultProps = {
  className: "",
  setTheme: () => {},
  selectedTheme: "",
};

export default styled(Footer)`
  width: 100%;
  max-width: 100%;
  background-color: ${palette.bgFooter};
  padding: 32px 8%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, max-content);
  // position: absolute;
  // bottom: 0;
  @media (max-width: 768px) {
    text-align: center;
    padding-bottom: 56px;
  }
  a {
    text-decoration: none;
  }
  box-shadow: ${palette.lightBS};
  .infoFooter {
    grid-column: 1 /-1;
    display: grid;
    grid-template-columns: 250px repeat(3, 1fr);
    grid-gap: 32px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }
  }
  img {
    width: 200px;
  }
  h5,
  a {
    font-size: 14px;
    color: ${palette.whiteWhite};
  }
  h4 {
    color: ${palette.whiteWhite};
    font-size: 16px;
    font-family: futura-pt, sans-serif;
  }
  hr {
    border-top: solid 1px rgb(65, 67, 75) !important;
  }
  ul {
    list-style: none;
    padding: 0;
    margin-top: 16px;
  }
  li {
    margin-top: 8px;
    color: ${palette.whiteWhite};
    font-size: 14px;
  }
  .horizontal-list {
    display: flex;
    justify-content: center;
    li {
      position: relative;
      display: flex;
      margin-right: 16px;
      overflow: hidden;
    }
  }
  .team-lapieza {
    max-width: 380px;
    width: 100%;
    justify-self: end;
    margin-top: -8rem;
    margin-right: -8rem;
    @media (max-width: 1300px) {
    }
    @media (max-width: 768px) {
      margin: 16px auto 16px auto;
    }
  }
  .switch-mode {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px auto;
    p {
      font-family: futura-pt, sans-serif;
      font-size: 14px;
      color: ${palette.whiteWhite};
      margin-right: 16px;
    }
  }
`;
