import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import logo from "../images/logo.png";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { palette } from "../styles/styles";

const NavBar = (props) => {
  const { className } = props;

  return (
    <nav className={className}>
      <a className="logo" href="https://bootcamp.lapieza.io">
        <img className="" src={logo} alt="lapieza" />
      </a>
      <div className="menu">
        <AnchorLink className="menuItem" to="/#content">
          ¿Qué incluye?
        </AnchorLink>
        <AnchorLink className="menuItem" to="/#serviceCharge">
          ¿Cómo funciona?
        </AnchorLink>
        <AnchorLink className="menuItem" to="/#successStories">
          Nuestros egresados
        </AnchorLink>
        <Link
          className="menuItem"
          to="/contact"
          activeStyle={{ color: palette.fontRed }}
        >
          Contacto
        </Link>
      </div>
    </nav>
  );
};

export default styled(NavBar)`
  padding: 0 8%;
  width: 100%;
  max-width: 100%;
  height: 10rem;
  display: grid;
  grid-template-columns: 15% 85%;
  font-size: 2.2rem;
  @media (max-width: 768px) {
    display: none;
  }
  .logo {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  .menu {
    padding: 0 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .menuItem {
      text-decoration: none;
      cursor: pointer;
      color: ${palette.navBlue};
    }
  }
`;
