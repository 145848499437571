import axios from "axios";

export const goPriorOrNext = (action, currentLength, current, setNextValue) => {
  if (action === "prior") {
    if (current === 0) {
      setNextValue(currentLength - 1);
    } else {
      setNextValue( current - 1);
    }
  } else if(action==="next") {
    if (current === currentLength - 1) {
      setNextValue(0);
    } else {
      setNextValue( current + 1);
    }
  }
};

export const countryToFlag = (isoCode = '' ) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode;
};
export const countries = [
  {
    nombre: "México",
    name: "Mexico",
    nom: "Mexique",
    iso2: "MX",
    iso3: "MEX",
    phoneCode: 52,
  },
  {
    nombre: "Afganistán",
    phoneCode: 93,
    iso2: "AF",
    nom: "Afghanistan",
    name: "Afghanistan",
  },
  {
    iso2: "AL",
    nombre: "Albania",
    iso3: "ALB",
    name: "Albania",
    phoneCode: 355,
  },
  {
    name: "Germany",
    phoneCode: 49,
    nom: "Allemagne",
    iso2: "DE",
    nombre: "Alemania",
  },
  {
    iso2: "AD",
    nom: "Andorra",
    phoneCode: 376,
    name: "Andorra",
    iso3: "AND",
  },
  {
    nom: "Angola",
    nombre: "Angola",
    name: "Angola",
    iso2: "AO",
    iso3: "AGO",
  },
  {
    name: "Anguilla",
    iso3: "AIA",
    nombre: "Anguila",
    iso2: "AI",
    nom: "Anguilla",
  },
  {
    iso3: "ATA",
    phoneCode: 672,
    nombre: "Antártida",
    name: "Antarctica",
    nom: "L'Antarctique",
  },
  {
    nombre: "Antigua y Barbuda",
    phoneCode: "1 268",
    name: "Antigua and Barbuda",
    iso3: "ATG",
    nom: "Antigua et Barbuda",
  },
  {
    name: "Saudi Arabia",
    nom: "Arabie Saoudite",
    iso3: "SAU",
    phoneCode: 966,
    nombre: "Arabia Saudita",
  },
  {
    phoneCode: 213,
    nom: "Algérie",
    iso2: "DZ",
    iso3: "DZA",
    name: "Algeria",
  },
  {
    nombre: "Argentina",
    phoneCode: 54,
    iso3: "ARG",
    name: "Argentina",
    nom: "Argentine",
  },
  {
    phoneCode: 374,
    name: "Armenia",
    iso3: "ARM",
    iso2: "AM",
    nom: "L'Arménie",
  },
  { phoneCode: 297, iso2: "AW", name: "Aruba", iso3: "ABW", nombre: "Aruba" },
  {
    nom: "Australie",
    name: "Australia",
    iso2: "AU",
    phoneCode: 61,
    nombre: "Australia",
  },
  {
    nombre: "Austria",
    iso3: "AUT",
    iso2: "AT",
    phoneCode: 43,
    nom: "Autriche",
  },
  {
    iso2: "AZ",
    nom: "L'Azerbaïdjan",
    phoneCode: 994,
    iso3: "AZE",
    name: "Azerbaijan",
  },
  {
    iso3: "BEL",
    iso2: "BE",
    nom: "Belgique",
    nombre: "Bélgica",
    name: "Belgium",
    phoneCode: 32,
  },
  {
    iso2: "BS",
    nom: "Bahamas",
    nombre: "Bahamas",
    name: "Bahamas",
    iso3: "BHS",
    phoneCode: 1242,
  },
  {
    name: "Bahrain",
    nom: "Bahreïn",
    iso2: "BH",
    iso3: "BHR",
    phoneCode: 973,
  },
  {
    iso2: "BD",
    name: "Bangladesh",
    nom: "Bangladesh",
    nombre: "Bangladesh",
    phoneCode: 880,
  },
  {
    iso3: "BRB",
    nombre: "Barbados",
    name: "Barbados",
    nom: "Barbade",
    iso2: "BB",
  },
  {
    iso2: "BZ",
    nombre: "Belice",
    nom: "Belize",
    name: "Belize",
    iso3: "BLZ",
  },
  { iso2: "BJ", phoneCode: 229, name: "Benin", nombre: "Benín", iso3: "BEN" },
  {
    phoneCode: 975,
    nom: "Le Bhoutan",
    name: "Bhutan",
    iso3: "BTN",
    iso2: "BT",
  },
  {
    iso2: "BY",
    phoneCode: 375,
    nom: "Biélorussie",
    nombre: "Bielorrusia",
    name: "Belarus",
  },
  {
    iso2: "MM",
    nombre: "Birmania",
    phoneCode: 95,
    iso3: "MMR",
    name: "Myanmar",
  },
  {
    iso2: "BO",
    nom: "Bolivie",
    phoneCode: 591,
    name: "Bolivia",
    iso3: "BOL",
  },
  {
    iso3: "BIH",
    iso2: "BA",
    phoneCode: 387,
    name: "Bosnia and Herzegovina",
    nom: "Bosnie-Herzégovine",
  },
  {
    nombre: "Botsuana",
    name: "Botswana",
    nom: "Botswana",
    phoneCode: 267,
    iso3: "BWA",
  },
  {
    nombre: "Brasil",
    nom: "Brésil",
    phoneCode: 55,
    name: "Brazil",
    iso2: "BR",
  },
  {
    nom: "Brunei",
    iso3: "BRN",
    iso2: "BN",
    nombre: "Brunéi",
    name: "Brunei",
  },
  {
    name: "Bulgaria",
    phoneCode: 359,
    nombre: "Bulgaria",
    iso3: "BGR",
    iso2: "BG",
  },
  {
    name: "Burkina Faso",
    iso2: "BF",
    phoneCode: 226,
    nom: "Burkina Faso",
    iso3: "BFA",
  },
  {
    nombre: "Burundi",
    name: "Burundi",
    iso3: "BDI",
    phoneCode: 257,
    iso2: "BI",
  },
  {
    nombre: "Cabo Verde",
    phoneCode: 238,
    nom: "Cap-Vert",
    iso3: "CPV",
    name: "Cape Verde",
  },
  {
    nom: "Cambodge",
    phoneCode: 855,
    iso3: "KHM",
    iso2: "KH",
    nombre: "Camboya",
  },
  {
    iso3: "CMR",
    name: "Cameroon",
    nom: "Cameroun",
    nombre: "Camerún",
    iso2: "CM",
  },
  { iso3: "CAN", nom: "Canada", iso2: "CA", nombre: "Canadá", phoneCode: 1 },
  { name: "Chad", nom: "Tchad", iso3: "TCD", nombre: "Chad", phoneCode: 235 },
  { iso2: "CL", name: "Chile", nom: "Chili", nombre: "Chile", iso3: "CHL" },
  { nombre: "China", iso2: "CN", nom: "Chine", name: "China", iso3: "CHN" },
  {
    name: "Cyprus",
    phoneCode: 357,
    nombre: "Chipre",
    nom: "Chypre",
    iso3: "CYP",
  },
  {
    iso2: "VA",
    name: "Vatican City State",
    iso3: "VAT",
    nom: "Cité du Vatican",
    phoneCode: 39,
  },
  {
    name: "Colombia",
    phoneCode: 57,
    iso2: "CO",
    nom: "Colombie",
    iso3: "COL",
  },
  {
    iso3: "COM",
    name: "Comoros",
    nombre: "Comoras",
    nom: "Comores",
    iso2: "KM",
  },
  {
    iso3: "COG",
    iso2: "CG",
    name: "Republic of the Congo",
    nombre: "República del Congo",
    nom: "République du Congo",
  },
  {
    nombre: "República Democrática del Congo",
    iso3: "COD",
    name: "Democratic Republic of the Congo",
    iso2: "CD",
    phoneCode: 243,
  },
  {
    iso3: "PRK",
    nom: "Corée du Nord",
    phoneCode: 850,
    name: "North Korea",
    iso2: "KP",
  },
  {
    nom: "Corée du Sud",
    name: "South Korea",
    iso3: "KOR",
    nombre: "Corea del Sur",
    phoneCode: 82,
  },
  {
    phoneCode: 225,
    iso3: "CIV",
    name: "Ivory Coast",
    nombre: "Costa de Marfil",
    iso2: "CI",
  },
  {
    nombre: "Costa Rica",
    iso3: "CRI",
    iso2: "CR",
    name: "Costa Rica",
    phoneCode: 506,
  },
  {
    iso2: "HR",
    iso3: "HRV",
    nombre: "Croacia",
    name: "Croatia",
    nom: "Croatie",
  },
  { nom: "Cuba", phoneCode: 53, iso2: "CU", name: "Cuba", nombre: "Cuba" },
  {
    name: "Curaçao",
    nom: "Curaçao",
    nombre: "Curazao",
    iso2: "CW",
    phoneCode: 5999,
  },
  {
    nombre: "Dinamarca",
    nom: "Danemark",
    name: "Denmark",
    phoneCode: 45,
    iso3: "DNK",
  },
  {
    phoneCode: "1 767",
    nombre: "Dominica",
    iso3: "DMA",
    name: "Dominica",
    iso2: "DM",
  },
  {
    iso3: "ECU",
    iso2: "EC",
    name: "Ecuador",
    nom: "Equateur",
    phoneCode: 593,
  },
  { name: "Egypt", iso2: "EG", iso3: "EGY", nom: "Egypte", phoneCode: 20 },
  {
    phoneCode: 503,
    nom: "El Salvador",
    name: "El Salvador",
    iso3: "SLV",
    nombre: "El Salvador",
  },
  {
    nombre: "Emiratos Árabes Unidos",
    nom: "Emirats Arabes Unis",
    phoneCode: 971,
    iso2: "AE",
    iso3: "ARE",
  },
  {
    name: "Eritrea",
    iso2: "ER",
    nom: "Erythrée",
    phoneCode: 291,
    iso3: "ERI",
  },
  {
    nombre: "Eslovaquia",
    phoneCode: 421,
    name: "Slovakia",
    iso2: "SK",
    nom: "Slovaquie",
  },
  {
    nombre: "Eslovenia",
    phoneCode: 386,
    nom: "Slovénie",
    iso3: "SVN",
    name: "Slovenia",
  },
  { iso2: "ES", nom: "Espagne", phoneCode: 34, name: "Spain", iso3: "ESP" },
  {
    phoneCode: 1,
    iso3: "USA",
    iso2: "US",
    name: "United States of America",
    nombre: "Estados Unidos de América",
  },
  {
    name: "Estonia",
    nom: "L'Estonie",
    nombre: "Estonia",
    iso2: "EE",
    iso3: "EST",
  },
  {
    name: "Ethiopia",
    phoneCode: 251,
    iso3: "ETH",
    nombre: "Etiopía",
    nom: "Ethiopie",
  },
  {
    phoneCode: 63,
    nombre: "Filipinas",
    name: "Philippines",
    nom: "Philippines",
    iso2: "PH",
  },
  {
    iso2: "FI",
    nom: "Finlande",
    iso3: "FIN",
    phoneCode: 358,
    name: "Finland",
  },
  { name: "Fiji", nombre: "Fiyi", iso3: "FJI", iso2: "FJ", nom: "Fidji" },
  { phoneCode: 33, nom: "France", iso3: "FRA", iso2: "FR", name: "France" },
  { iso2: "GA", nom: "Gabon", nombre: "Gabón", iso3: "GAB", name: "Gabon" },
  { phoneCode: 220, iso3: "GMB", nom: "Gambie", iso2: "GM", name: "Gambia" },
  {
    iso2: "GE",
    nom: "Géorgie",
    iso3: "GEO",
    nombre: "Georgia",
    name: "Georgia",
  },
  { iso2: "GH", iso3: "GHA", name: "Ghana", nombre: "Ghana", phoneCode: 233 },
  {
    iso3: "GIB",
    phoneCode: 350,
    nom: "Gibraltar",
    iso2: "GI",
    name: "Gibraltar",
  },
  {
    iso2: "GD",
    phoneCode: "1 473",
    nom: "Grenade",
    nombre: "Granada",
    name: "Grenada",
  },
  {
    nombre: "Grecia",
    name: "Greece",
    phoneCode: 30,
    nom: "Grèce",
    iso2: "GR",
  },
  {
    iso3: "GRL",
    name: "Greenland",
    nombre: "Groenlandia",
    iso2: "GL",
    nom: "Groenland",
  },
  {
    phoneCode: 590,
    nom: "Guadeloupe",
    iso2: "GP",
    nombre: "Guadalupe",
    name: "Guadeloupe",
  },
  {
    nombre: "Guam",
    iso3: "GUM",
    phoneCode: "1 671",
    name: "Guam",
    iso2: "GU",
  },
  {
    phoneCode: 502,
    nom: "Guatemala",
    name: "Guatemala",
    iso3: "GTM",
    nombre: "Guatemala",
  },
  {
    nombre: "Guayana Francesa",
    name: "French Guiana",
    phoneCode: 594,
    nom: "Guyane française",
    iso3: "GUF",
  },
  {
    nom: "Guernesey",
    iso2: "GG",
    phoneCode: 44,
    name: "Guernsey",
    nombre: "Guernsey",
  },
  {
    iso3: "GIN",
    nom: "Guinée",
    phoneCode: 224,
    nombre: "Guinea",
    name: "Guinea",
  },
  {
    iso3: "GNQ",
    nombre: "Guinea Ecuatorial",
    nom: "Guinée Equatoriale",
    name: "Equatorial Guinea",
    phoneCode: 240,
  },
  {
    iso2: "GW",
    nombre: "Guinea-Bissau",
    phoneCode: 245,
    iso3: "GNB",
    nom: "Guinée-Bissau",
  },
  { name: "Guyana", phoneCode: 592, iso2: "GY", nom: "Guyane", iso3: "GUY" },
  {
    phoneCode: 509,
    nombre: "Haití",
    nom: "Haïti",
    name: "Haiti",
    iso2: "HT",
  },
  {
    name: "Honduras",
    nombre: "Honduras",
    iso2: "HN",
    nom: "Honduras",
    iso3: "HND",
  },
  {
    nombre: "Hong kong",
    iso2: "HK",
    iso3: "HKG",
    phoneCode: 852,
    name: "Hong Kong",
  },
  {
    nombre: "Hungría",
    iso2: "HU",
    phoneCode: 36,
    name: "Hungary",
    iso3: "HUN",
  },
  { name: "India", nombre: "India", phoneCode: 91, nom: "Inde", iso2: "IN" },
  {
    phoneCode: 62,
    name: "Indonesia",
    nombre: "Indonesia",
    nom: "Indonésie",
    iso3: "IDN",
  },
  { phoneCode: 98, iso2: "IR", nom: "Iran", iso3: "IRN", name: "Iran" },
  { iso3: "IRQ", name: "Iraq", nom: "Irak", phoneCode: 964, iso2: "IQ" },
  {
    iso2: "IE",
    nombre: "Irlanda",
    name: "Ireland",
    nom: "Irlande",
    iso3: "IRL",
  },
  {
    phoneCode: "",
    nom: "Bouvet Island",
    nombre: "Isla Bouvet",
    name: "Bouvet Island",
    iso3: "BVT",
  },
  {
    nom: "Ile de Man",
    nombre: "Isla de Man",
    phoneCode: 44,
    iso3: "IMN",
    iso2: "IM",
  },
  {
    nom: "Île de Norfolk",
    iso2: "NF",
    nombre: "Isla Norfolk",
    phoneCode: 672,
    name: "Norfolk Island",
  },
  {
    iso2: "IS",
    iso3: "ISL",
    name: "Iceland",
    nombre: "Islandia",
    phoneCode: 354,
  },
  {
    phoneCode: "1 441",
    iso3: "BMU",
    iso2: "BM",
    name: "Bermuda Islands",
    nom: "Bermudes",
  },
  {
    nom: "Iles Caïmans",
    iso3: "CYM",
    name: "Cayman Islands",
    iso2: "KY",
    nombre: "Islas Caimán",
  },
  {
    iso2: "CC",
    nombre: "Islas Cocos (Keeling)",
    phoneCode: 61,
    iso3: "CCK",
    nom: "Cocos (Keeling",
  },
  {
    iso2: "CK",
    nom: "Iles Cook",
    nombre: "Islas Cook",
    phoneCode: 682,
    iso3: "COK",
  },
  {
    name: "Åland Islands",
    nom: "Îles Åland",
    iso3: "ALA",
    phoneCode: 358,
    nombre: "Islas de Åland",
  },
  {
    iso3: "FRO",
    iso2: "FO",
    name: "Faroe Islands",
    nombre: "Islas Feroe",
    nom: "Iles Féro",
  },
  {
    nom: "Géorgie du Sud et les Îles Sandwich du Sud",
    name: "South Georgia and the South Sandwich Islands",
    nombre: "Islas Georgias del Sur y Sandwich del Sur",
    iso2: "GS",
    iso3: "SGS",
  },
  {
    nombre: "Islas Heard y McDonald",
    nom: "Les îles Heard et McDonald",
    name: "Heard Island and McDonald Islands",
    iso3: "HMD",
    iso2: "HM",
  },
  {
    iso2: "MV",
    phoneCode: 960,
    iso3: "MDV",
    name: "Maldives",
    nombre: "Islas Maldivas",
  },
  {
    iso2: "FK",
    phoneCode: 500,
    nom: "Iles Falkland (Malvinas",
    iso3: "FLK",
    name: "Falkland Islands (Malvinas)",
  },
  {
    iso2: "MP",
    name: "Northern Mariana Islands",
    nombre: "Islas Marianas del Norte",
    phoneCode: "1 670",
    nom: "Iles Mariannes du Nord",
  },
  {
    iso2: "MH",
    phoneCode: 692,
    nom: "Iles Marshall",
    nombre: "Islas Marshall",
    name: "Marshall Islands",
  },
  {
    iso3: "PCN",
    nombre: "Islas Pitcairn",
    phoneCode: 870,
    nom: "Iles Pitcairn",
    name: "Pitcairn Islands",
  },
  {
    name: "Solomon Islands",
    iso2: "SB",
    phoneCode: 677,
    nom: "Iles Salomon",
    iso3: "SLB",
  },
  {
    iso3: "TCA",
    nom: "Iles Turques et Caïques",
    nombre: "Islas Turcas y Caicos",
    iso2: "TC",
    name: "Turks and Caicos Islands",
  },
  {
    iso3: "UMI",
    phoneCode: 246,
    iso2: "UM",
    nombre: "Islas Ultramarinas Menores de Estados Unidos",
    name: "United States Minor Outlying Islands",
  },
  {
    iso3: "VGB",
    name: "Virgin Islands",
    nombre: "Islas Vírgenes Británicas",
    phoneCode: "1 284",
    nom: "Iles Vierges",
  },
  {
    phoneCode: "1 340",
    nom: "Îles Vierges américaines",
    nombre: "Islas Vírgenes de los Estados Unidos",
    iso2: "VI",
    iso3: "VIR",
  },
  {
    name: "Israel",
    nom: "Israël",
    nombre: "Israel",
    iso3: "ISR",
    phoneCode: 972,
  },
  { iso2: "IT", iso3: "ITA", nombre: "Italia", nom: "Italie", name: "Italy" },
  {
    iso3: "JAM",
    iso2: "JM",
    nombre: "Jamaica",
    phoneCode: "1 876",
    name: "Jamaica",
  },
  { nombre: "Japón", name: "Japan", nom: "Japon", iso3: "JPN", iso2: "JP" },
  {
    phoneCode: 44,
    iso3: "JEY",
    nombre: "Jersey",
    name: "Jersey",
    iso2: "JE",
  },
  {
    iso2: "JO",
    iso3: "JOR",
    name: "Jordan",
    nombre: "Jordania",
    phoneCode: 962,
  },
  {
    iso3: "KAZ",
    nom: "Le Kazakhstan",
    phoneCode: 7,
    name: "Kazakhstan",
    iso2: "KZ",
  },
  { nombre: "Kenia", name: "Kenya", iso2: "KE", nom: "Kenya", iso3: "KEN" },
  {
    iso2: "KG",
    nombre: "Kirguistán",
    name: "Kyrgyzstan",
    iso3: "KGZ",
    phoneCode: 996,
  },
  {
    name: "Kiribati",
    iso3: "KIR",
    iso2: "KI",
    nom: "Kiribati",
    nombre: "Kiribati",
  },
  {
    nom: "Koweït",
    iso3: "KWT",
    nombre: "Kuwait",
    iso2: "KW",
    phoneCode: 965,
  },
  {
    phoneCode: 961,
    name: "Lebanon",
    iso2: "LB",
    nom: "Liban",
    nombre: "Líbano",
  },
  { iso3: "LAO", phoneCode: 856, iso2: "LA", nom: "Laos", name: "Laos" },
  {
    phoneCode: 266,
    name: "Lesotho",
    iso2: "LS",
    nom: "Lesotho",
    iso3: "LSO",
  },
  {
    nom: "La Lettonie",
    iso2: "LV",
    phoneCode: 371,
    nombre: "Letonia",
    name: "Latvia",
  },
  {
    iso3: "LBR",
    nom: "Liberia",
    name: "Liberia",
    nombre: "Liberia",
    phoneCode: 231,
  },
  {
    phoneCode: 218,
    name: "Libya",
    iso2: "LY",
    nom: "Libye",
    nombre: "Libia",
  },
  {
    name: "Liechtenstein",
    iso2: "LI",
    nom: "Liechtenstein",
    iso3: "LIE",
    phoneCode: 423,
  },
  {
    name: "Lithuania",
    iso3: "LTU",
    iso2: "LT",
    phoneCode: 370,
    nombre: "Lituania",
  },
  {
    phoneCode: 352,
    iso3: "LUX",
    iso2: "LU",
    nom: "Luxembourg",
    name: "Luxembourg",
  },
  {
    name: "Mexico",
    nombre: "México",
    nom: "Mexique",
    iso2: "MX",
    iso3: "MEX",
  },
  {
    iso3: "MCO",
    nom: "Monaco",
    iso2: "MC",
    name: "Monaco",
    nombre: "Mónaco",
  },
  { iso3: "MAC", nom: "Macao", phoneCode: 853, iso2: "MO", nombre: "Macao" },
  {
    nom: "Macédoine",
    nombre: "Macedônia",
    phoneCode: 389,
    iso3: "MKD",
    name: "Macedonia",
  },
  {
    iso3: "MDG",
    nom: "Madagascar",
    iso2: "MG",
    phoneCode: 261,
    nombre: "Madagascar",
  },
  {
    iso3: "MYS",
    name: "Malaysia",
    nombre: "Malasia",
    phoneCode: 60,
    nom: "Malaisie",
  },
  {
    iso3: "MWI",
    name: "Malawi",
    iso2: "MW",
    nombre: "Malawi",
    phoneCode: 265,
  },
  { nom: "Mali", iso3: "MLI", name: "Mali", iso2: "ML", nombre: "Mali" },
  {
    iso2: "MT",
    nom: "Malte",
    nombre: "Malta",
    name: "Malta",
    phoneCode: 356,
  },
  {
    name: "Morocco",
    iso3: "MAR",
    nom: "Maroc",
    iso2: "MA",
    nombre: "Marruecos",
  },
  {
    nombre: "Martinica",
    iso2: "MQ",
    iso3: "MTQ",
    nom: "Martinique",
    name: "Martinique",
  },
  {
    name: "Mauritius",
    iso3: "MUS",
    phoneCode: 230,
    iso2: "MU",
    nombre: "Mauricio",
  },
  {
    name: "Mauritania",
    iso2: "MR",
    iso3: "MRT",
    nom: "Mauritanie",
    nombre: "Mauritania",
  },
  {
    phoneCode: 262,
    nom: "Mayotte",
    iso3: "MYT",
    iso2: "YT",
    nombre: "Mayotte",
  },
  {
    phoneCode: 691,
    nombre: "Micronesia",
    nom: "Federados Estados de",
    name: "Estados Federados de",
    iso2: "FM",
  },
  {
    iso3: "MDA",
    nombre: "Moldavia",
    phoneCode: 373,
    iso2: "MD",
    name: "Moldova",
  },
  {
    nom: "Mongolie",
    name: "Mongolia",
    nombre: "Mongolia",
    phoneCode: 976,
    iso3: "MNG",
  },
  {
    iso3: "MNE",
    nom: "Monténégro",
    iso2: "ME",
    name: "Montenegro",
    nombre: "Montenegro",
  },
  {
    iso2: "MS",
    nom: "Montserrat",
    name: "Montserrat",
    iso3: "MSR",
    nombre: "Montserrat",
  },
  {
    name: "Mozambique",
    iso3: "MOZ",
    nom: "Mozambique",
    iso2: "MZ",
    nombre: "Mozambique",
  },
  {
    iso2: "NA",
    nom: "Namibie",
    name: "Namibia",
    iso3: "NAM",
    nombre: "Namibia",
  },
  { iso2: "NR", nom: "Nauru", name: "Nauru", phoneCode: 674, iso3: "NRU" },
  { phoneCode: 977, nom: "Népal", name: "Nepal", iso3: "NPL", iso2: "NP" },
  {
    nombre: "Nicaragua",
    nom: "Nicaragua",
    name: "Nicaragua",
    iso3: "NIC",
    iso2: "NI",
  },
  { iso3: "NER", name: "Niger", nombre: "Niger", nom: "Niger", iso2: "NE" },
  {
    nombre: "Nigeria",
    iso2: "NG",
    iso3: "NGA",
    phoneCode: 234,
    nom: "Nigeria",
  },
  { nombre: "Niue", name: "Niue", iso2: "NU", phoneCode: 683, iso3: "NIU" },
  {
    nom: "Norvège",
    nombre: "Noruega",
    name: "Norway",
    iso3: "NOR",
    phoneCode: 47,
  },
  {
    nom: "Nouvelle-Calédonie",
    name: "New Caledonia",
    iso3: "NCL",
    phoneCode: 687,
    iso2: "NC",
  },
  {
    iso2: "NZ",
    iso3: "NZL",
    name: "New Zealand",
    phoneCode: 64,
    nom: "Nouvelle-Zélande",
  },
  { name: "Oman", nombre: "Omán", iso2: "OM", nom: "Oman", iso3: "OMN" },
  {
    iso3: "NLD",
    iso2: "NL",
    nom: "Pays-Bas",
    phoneCode: 31,
    name: "Netherlands",
  },
  {
    nombre: "Pakistán",
    iso2: "PK",
    phoneCode: 92,
    name: "Pakistan",
    nom: "Pakistan",
  },
  {
    name: "Palau",
    nom: "Palau",
    nombre: "Palau",
    phoneCode: 680,
    iso3: "PLW",
  },
  {
    iso3: "PSE",
    phoneCode: 970,
    iso2: "PS",
    nombre: "Palestina",
    name: "Palestine",
  },
  {
    name: "Panama",
    nombre: "Panamá",
    iso3: "PAN",
    phoneCode: 507,
    nom: "Panama",
  },
  {
    nombre: "Papúa Nueva Guinea",
    phoneCode: 675,
    iso3: "PNG",
    nom: "Papouasie-Nouvelle-Guinée",
    iso2: "PG",
  },
  {
    nombre: "Paraguay",
    iso2: "PY",
    nom: "Paraguay",
    name: "Paraguay",
    iso3: "PRY",
  },
  { phoneCode: 51, iso2: "PE", name: "Peru", nom: "Pérou", iso3: "PER" },
  {
    nom: "Polynésie française",
    nombre: "Polinesia Francesa",
    name: "French Polynesia",
    iso2: "PF",
    phoneCode: 689,
  },
  { nom: "Pologne", iso3: "POL", name: "Poland", iso2: "PL", phoneCode: 48 },
  {
    phoneCode: 351,
    nom: "Portugal",
    iso3: "PRT",
    iso2: "PT",
    name: "Portugal",
  },
  {
    nom: "Porto Rico",
    nombre: "Puerto Rico",
    phoneCode: 1,
    name: "Puerto Rico",
    iso2: "PR",
  },
  { iso2: "QA", name: "Qatar", iso3: "QAT", phoneCode: 974, nombre: "Qatar" },
  {
    nombre: "Reino Unido",
    iso3: "GBR",
    nom: "Royaume-Uni",
    phoneCode: 44,
    iso2: "GB",
  },
  {
    iso2: "CF",
    name: "Central African Republic",
    nombre: "República Centroafricana",
    nom: "République Centrafricaine",
    phoneCode: 236,
  },
  {
    iso3: "CZE",
    nom: "République Tchèque",
    iso2: "CZ",
    nombre: "República Checa",
    name: "Czech Republic",
  },
  {
    name: "Dominican Republic",
    iso2: "DO",
    phoneCode: "1 809",
    nom: "République Dominicaine",
    iso3: "DOM",
  },
  {
    iso2: "SS",
    phoneCode: 211,
    nom: "Soudan du Sud",
    nombre: "República de Sudán del Sur",
    iso3: "SSD",
  },
  {
    name: "Réunion",
    iso2: "RE",
    iso3: "REU",
    nombre: "Reunión",
    phoneCode: 262,
  },
  {
    nom: "Rwanda",
    name: "Rwanda",
    phoneCode: 250,
    iso3: "RWA",
    nombre: "Ruanda",
  },
  {
    nombre: "Rumanía",
    iso2: "RO",
    name: "Romania",
    nom: "Roumanie",
    phoneCode: 40,
  },
  {
    iso3: "RUS",
    nom: "La Russie",
    name: "Russia",
    iso2: "RU",
    nombre: "Rusia",
  },
  {
    name: "Western Sahara",
    iso3: "ESH",
    iso2: "EH",
    phoneCode: 212,
    nombre: "Sahara Occidental",
  },
  { name: "Samoa", iso3: "WSM", nom: "Samoa", iso2: "WS", phoneCode: 685 },
  {
    iso2: "AS",
    phoneCode: "1 684",
    name: "American Samoa",
    iso3: "ASM",
    nom: "Les Samoa américaines",
  },
  {
    iso3: "BLM",
    iso2: "BL",
    phoneCode: 590,
    nom: "Saint-Barthélemy",
    name: "Saint Barthélemy",
  },
  {
    iso2: "KN",
    name: "Saint Kitts and Nevis",
    nom: "Saint Kitts et Nevis",
    iso3: "KNA",
    nombre: "San Cristóbal y Nieves",
  },
  {
    iso3: "SMR",
    nom: "San Marino",
    name: "San Marino",
    iso2: "SM",
    phoneCode: 378,
  },
  {
    iso2: "MF",
    nom: "Saint-Martin (partie française)",
    phoneCode: "1 599",
    nombre: "San Martín (Francia)",
    name: "Saint Martin (French part)",
  },
  {
    nom: "Saint-Pierre-et-Miquelon",
    iso3: "SPM",
    nombre: "San Pedro y Miquelón",
    iso2: "PM",
    phoneCode: 508,
  },
  {
    phoneCode: "1 784",
    nom: "Saint-Vincent et Grenadines",
    iso2: "VC",
    iso3: "VCT",
    nombre: "San Vicente y las Granadinas",
  },
  {
    nom: "Ascensión y Tristan de Acuña",
    nombre: "Santa Elena",
    iso3: "SHN",
    phoneCode: 290,
    iso2: "SH",
  },
  {
    iso2: "LC",
    iso3: "LCA",
    name: "Saint Lucia",
    nom: "Sainte-Lucie",
    nombre: "Santa Lucía",
  },
  {
    nom: "Sao Tomé et Principe",
    phoneCode: 239,
    iso2: "ST",
    name: "Sao Tome and Principe",
    nombre: "Santo Tomé y Príncipe",
  },
  {
    iso2: "SN",
    phoneCode: 221,
    nom: "Sénégal",
    name: "Senegal",
    nombre: "Senegal",
  },
  {
    iso3: "SRB",
    name: "Serbia",
    phoneCode: 381,
    nombre: "Serbia",
    iso2: "RS",
  },
  {
    phoneCode: 248,
    name: "Seychelles",
    iso3: "SYC",
    nombre: "Seychelles",
    nom: "Les Seychelles",
  },
  {
    nom: "Sierra Leone",
    phoneCode: 232,
    iso3: "SLE",
    name: "Sierra Leone",
    nombre: "Sierra Leona",
  },
  {
    nombre: "Singapur",
    iso3: "SGP",
    phoneCode: 65,
    nom: "Singapour",
    iso2: "SG",
  },
  {
    nombre: "Sint Maarten",
    name: "Sint Maarten",
    iso3: "SMX",
    phoneCode: "1 721",
    iso2: "SX",
  },
  { phoneCode: 963, iso2: "SY", name: "Syria", nombre: "Siria", iso3: "SYR" },
  {
    nom: "Somalie",
    name: "Somalia",
    nombre: "Somalia",
    phoneCode: 252,
    iso3: "SOM",
  },
  {
    iso3: "LKA",
    name: "Sri Lanka",
    phoneCode: 94,
    iso2: "LK",
    nombre: "Sri lanka",
  },
  {
    phoneCode: 27,
    name: "South Africa",
    nombre: "Sudáfrica",
    iso3: "ZAF",
    nom: "Afrique du Sud",
  },
  {
    name: "Sudan",
    nom: "Soudan",
    phoneCode: 249,
    iso3: "SDN",
    nombre: "Sudán",
  },
  { iso2: "SE", nom: "Suède", nombre: "Suecia", phoneCode: 46, iso3: "SWE" },
  {
    name: "Switzerland",
    nombre: "Suiza",
    phoneCode: 41,
    iso2: "CH",
    iso3: "CHE",
  },
  {
    iso2: "SR",
    iso3: "SUR",
    phoneCode: 597,
    nom: "Surinam",
    name: "Suriname",
  },
  {
    nom: "Svalbard et Jan Mayen",
    name: "Svalbard and Jan Mayen",
    iso2: "SJ",
    nombre: "Svalbard y Jan Mayen",
    phoneCode: 47,
  },
  {
    name: "Swaziland",
    nom: "Swaziland",
    iso3: "SWZ",
    phoneCode: 268,
    iso2: "SZ",
  },
  {
    iso2: "TJ",
    iso3: "TJK",
    phoneCode: 992,
    nombre: "Tayikistán",
    name: "Tajikistan",
  },
  {
    nombre: "Tailandia",
    iso2: "TH",
    name: "Thailand",
    phoneCode: 66,
    nom: "Thaïlande",
  },
  {
    iso2: "TW",
    phoneCode: 886,
    iso3: "TWN",
    nom: "Taiwan",
    nombre: "Taiwán",
  },
  {
    iso2: "TZ",
    name: "Tanzania",
    nombre: "Tanzania",
    nom: "Tanzanie",
    iso3: "TZA",
  },
  {
    iso3: "IOT",
    iso2: "IO",
    nom: "Territoire britannique de l'océan Indien",
    name: "British Indian Ocean Territory",
    nombre: "Territorio Británico del Océano Índico",
  },
  {
    nom: "Terres australes françaises",
    phoneCode: "",
    iso2: "TF",
    name: "French Southern Territories",
    iso3: "ATF",
  },
  {
    phoneCode: 670,
    iso3: "TLS",
    nom: "Timor-Oriental",
    name: "East Timor",
    nombre: "Timor Oriental",
  },
  { nom: "Togo", iso2: "TG", iso3: "TGO", nombre: "Togo", phoneCode: 228 },
  {
    name: "Tokelau",
    nombre: "Tokelau",
    nom: "Tokélaou",
    phoneCode: 690,
    iso3: "TKL",
  },
  { nom: "Tonga", name: "Tonga", phoneCode: 676, iso2: "TO", iso3: "TON" },
  {
    iso2: "TT",
    name: "Trinidad and Tobago",
    nom: "Trinidad et Tobago",
    iso3: "TTO",
    phoneCode: "1 868",
  },
  {
    iso2: "TN",
    name: "Tunisia",
    phoneCode: 216,
    nombre: "Tunez",
    iso3: "TUN",
  },
  {
    nom: "Le Turkménistan",
    name: "Turkmenistan",
    phoneCode: 993,
    nombre: "Turkmenistán",
    iso2: "TM",
  },
  {
    iso2: "TR",
    nombre: "Turquía",
    phoneCode: 90,
    nom: "Turquie",
    name: "Turkey",
  },
  {
    iso3: "TUV",
    name: "Tuvalu",
    nombre: "Tuvalu",
    iso2: "TV",
    nom: "Tuvalu",
  },
  {
    nombre: "Ucrania",
    phoneCode: 380,
    nom: "L'Ukraine",
    iso2: "UA",
    name: "Ukraine",
  },
  {
    phoneCode: 256,
    iso2: "UG",
    nombre: "Uganda",
    nom: "Ouganda",
    name: "Uganda",
  },
  {
    phoneCode: 598,
    name: "Uruguay",
    iso3: "URY",
    nombre: "Uruguay",
    iso2: "UY",
  },
  {
    nombre: "Uzbekistán",
    phoneCode: 998,
    name: "Uzbekistan",
    nom: "L'Ouzbékistan",
    iso3: "UZB",
  },
  {
    nombre: "Vanuatu",
    nom: "Vanuatu",
    phoneCode: 678,
    iso2: "VU",
    iso3: "VUT",
  },
  {
    name: "Venezuela",
    nombre: "Venezuela",
    phoneCode: 58,
    iso3: "VEN",
    iso2: "VE",
  },
  {
    nombre: "Vietnam",
    iso3: "VNM",
    name: "Vietnam",
    iso2: "VN",
    phoneCode: 84,
  },
  {
    phoneCode: 681,
    iso2: "WF",
    name: "Wallis and Futuna",
    iso3: "WLF",
    nom: "Wallis et Futuna",
  },
  { nom: "Yémen", iso2: "YE", nombre: "Yemen", name: "Yemen", iso3: "YEM" },
  {
    phoneCode: 253,
    iso2: "DJ",
    name: "Djibouti",
    nombre: "Yibuti",
    iso3: "DJI",
  },
  {
    name: "Zambia",
    nom: "Zambie",
    iso3: "ZMB",
    nombre: "Zambia",
    iso2: "ZM",
  },
  {
    name: "Zimbabwe",
    nombre: "Zimbabue",
    nom: "Zimbabwe",
    iso2: "ZW",
    phoneCode: 263,
  },

];

export const sendEmail = async (data = {}) => {
  await axios.request({
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    url: "https://us-central1-pieza-development.cloudfunctions.net/sendMailCandidateInvitation",
    method: "POST",
    data,
    crossDomain: true,
  });
};
