import styled, { css } from "styled-components";

export const palette = {
  navBlue: "#01032D",
  fontBlue: "#1B1464",
  fontRed: "#FF5048",
  bgRed: "#FF4A42",
  bgWhite: "#FCFCFC",
  whiteWhite: "#FFFFFF",
  blackBlack: "#000000",
  boxShadow: "#707070",
  blueNumbers: "#1F3BE6",
  iconGreen: "#014D43",
  bgFaq: "rgba(92, 122, 234, .1)",
  bgFooter: '#303E46',
  borderBlue: "#27206C",
  borderInput: '#D1D1D1',
  bgMenu: '#14279B',
  bgProfiles: '#AFC0FD',
  fontAnswers: "#1F1F1F",
  fontModDesc: "#707070",
  greenSuccesInfo: "#014D43",
  regBS: "rgba(0, 0, 0, 0.16) 0px 3px 10px",
  heavyBS: "rgba(0, 0, 0, 0.43) 0px 0px 43px -8px",
  lightBS:
    " 0 0.3px 1.1px -23px rgba(0, 0, 0, 0.016),\n" +
    "  0 0.7px 2.4px -23px rgba(0, 0, 0, 0.023),\n" +
    "  0 1.1px 4.1px -23px rgba(0, 0, 0, 0.028),\n" +
    "  0 1.7px 6.4px -23px rgba(0, 0, 0, 0.033),\n" +
    "  0 2.6px 9.4px -23px rgba(0, 0, 0, 0.037),\n" +
    "  0 3.8px 13.8px -23px rgba(0, 0, 0, 0.042),\n" +
    "  0 5.7px 20.8px -23px rgba(0, 0, 0, 0.047),\n" +
    "  0 9.1px 33.1px -23px rgba(0, 0, 0, 0.054),\n" +
    "  0 17px 62px -23px rgba(0, 0, 0, 0.07)",
  BSnewCard:
    " 0 0.3px 1.1px -23px rgba(0, 0, 0, 0.016),\n" +
    "  0 0.7px 2.4px -23px rgba(0, 0, 0, 0.023),\n" +
    "  0 1.1px 4.1px -23px rgba(0, 0, 0, 0.028),\n" +
    "  0 1.7px 6.4px -23px rgba(0, 0, 0, 0.033),\n" +
    "  0 2.6px 9.4px -23px rgba(0, 0, 0, 0.037),\n" +
    "  0 3.8px 13.8px -23px rgba(0, 0, 0, 0.042),\n" +
    "  0 5.7px 20.8px -23px rgba(0, 0, 0, 0.047),\n" +
    "  0 9.1px 33.1px -23px rgba(0, 0, 0, 0.054),\n" +
    "  0 17px 62px -23px rgba(0, 0, 0, 0.07)",
};

export const Wrapper = styled.div`
  padding: 0 8%;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(50%, 60%);
  grid-row-gap: 12%;
`;

export const applyButton = css`
  font-family: "SofiaProSemiBoldAz", sans-serif;
  background-color: ${palette.bgRed};
  color: ${palette.whiteWhite};
  padding: 1.5rem 4rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
`;

export const chevronsButton = css`
  background-color: transparent;
  border: none;
  outline: none;
  .icon {
    width: 2rem;
    color: ${palette.fontRed};
    cursor: pointer;
  }
`;

export const textAlignCenter768 = css`
  @media (max-width: 768px){
    text-align: center;
    justify-content: center;
  }
`;

export const sectionLayout = css`
  padding: 0 8%;
  width: 100%;
`;

export const flexRowStartStart = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const flexRowSeCt = css`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const flexRowStartCenter = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
export const flexRowSbCt = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const flexColStartCenter = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const flexColStSt = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const flexColSbCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const flexColCtCt = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const flexColEndCt = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;